import { graphql } from "gatsby"
import React from "react"
import Buttons from "../components/common/button"
import CTA from "../components/common/CTA"
import Frame from "../components/common/frame"
import SparkCarousal from "../components/common/SparkCarousal"
import VerticalTabs from "../components/common/VerticalTabComponent/VerticalTabs"
import Navigation from "../components/navigation"
import "../styles/pages/chat-lp.scss"

const chatLP = ({ data }) => {
  const { heroSection, seo, ctaBoxes, newField } = data.SuperOps.pages[0]
  const carousalData = newField[0]
  const faqData = newField[1]

  return (
    <div className="chat-landing-page">
      <Frame seo={seo} footerClass="footer" newButton>
        <header>
          <Navigation page="Home" dskScroll={400} newButton />
        </header>

        <div className="bg-clr mx-auto">
          <section className="hero-container prelative mx-auto">
            <div className="layout-box mx-auto">
              <div className="d-flex align-items-center justify-content-center mb24">
                <p className="tag font-white p16 fw-600 tag m-0 down-to-up-1">
                  {heroSection.tag}
                </p>
              </div>

              <h1 className="heading down-to-up-2 font-roboto fw-bold font-white heading mx-auto text-center mb24">
                {heroSection.heroHeading.text}
              </h1>

              <p className="down-to-up-3 font-white p18 description mx-auto text-center">
                {heroSection.content.text}
              </p>

              <div className="hero-btn d-flex justify-content-center mx-auto down-to-up-4">
                <Buttons
                  theme="primary-new"
                  text={heroSection.primaryButtonText}
                  link={heroSection.primaryButtonLink}
                />
              </div>
            </div>
          </section>

          <section className="carousal-wrapper">
            <SparkCarousal carousalData={carousalData} />
          </section>
        </div>

        <section className="faq-container">
          <VerticalTabs data={faqData} />
        </section>

        <section className="cta-container two-buttons">
          <CTA
            data={[ctaBoxes[0]]}
            className="Layout-container"
            lgLeft={6}
            lgRight={4}
            newButton
            newDesign
            isSecondaryBtn
          />
        </section>
      </Frame>
    </div>
  )
}

export default chatLP

export const query = graphql`
  query chatLandingPage {
    SuperOps {
      pages(where: { title: "Chat-LP" }) {
        heroSection {
          tag
          heroHeading: heading {
            text
          }
          content {
            text
          }
          primaryButtonText
          primaryButtonLink
        }

        seo {
          title
          description
          keywords
          image {
            url
          }
        }

        ctaBoxes {
          type
          theme
          customBackgroundColor
          heading {
            html
          }
          description {
            html
          }
          primaryButtonText
          primaryButtonLink
          secondaryButtonText
          secondaryButtonLink
          card {
            cardName
            title
            subtext
            slug
          }
        }

        newField {
          ... on SuperOps_Card {
            title
            heading
            cardName
            cardDescription
            slug
            links
            text
            content {
              html
            }
            subtext
            image {
              url
            }
          }

          ... on SuperOps_FaqComponent {
            __typename
            faqHeading: heading
            faqDescription: description
            ctaText {
              text
              html
            }
            faqType
            buttonText: text
            link
            faqs {
              illustrationPattern
              question
              answerrt {
                html
                text
              }
              description
              linkText
              slug
              images {
                url
                fileName
              }
            }
          }
        }
      }
    }
  }
`
